import { StatsContexterService } from 'pf-frontend-common/src/service/stats-contexter/service';

import { configGetSettings } from 'common/helper/config/get-settings';
import { AbTestService } from 'common/service/ab-test/service';
import { FooterService } from 'common/service/footer/service';
import { HeaderService } from 'common/service/header/service';
import { LanguageSelectorService } from 'common/service/language-selector/service';
import { StatsService } from 'common/service/stats/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';
import { ErrorPageBundleSettingsInterface } from 'mobile/entry/error-page/bundle/settings.interface';
import { UserAvatarComponent } from 'mobile/module/user/avatar/component';

AbTestService().initialize();

const bundleSettings = configGetSettings<ErrorPageBundleSettingsInterface>();
const statsContexterService = StatsContexterService();
const statsService = StatsService();

// Common services
UserAuthenticationService();
FooterService();
HeaderService()
  .setComponents({
    UserAvatarComponent,
  })
  .render();

LanguageSelectorService();

// Log stats (eventually move to ErrorPageView)
statsContexterService.setErrorPage(true);
statsService.pageView();
statsService.errorPage({
  http_status_code: bundleSettings.error.code,
});
