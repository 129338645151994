import { configGetSettings } from 'common/helper/config/get-settings';
import { settingsGetPageType } from 'common/helper/settings/get-page-type';
import { preactConnectStore } from 'common/module/preact/connect-store';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { UserAuthTrackerFactory } from 'common/module/user/auth/tracker.factory';
import { userAvatarStatsGtmClickUserProfile } from 'common/module/user/avatar/stats/gtm/click-user-profile';
import { UserAvatarTemplate } from 'common/module/user/avatar/template';
import { UserAvatarTemplatePropsInterface } from 'common/module/user/avatar/template-props.interface';
import { UserAvatarViewStore } from 'common/module/user/avatar/view-store';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';
import { UserAvatarService } from 'common/service/user-avatar/service';

export const UserAvatarComponent = preactConnectStore<UserAvatarViewStore, UserAvatarTemplatePropsInterface>(
  UserAvatarService,
  (store) => ({
    ...store.getState(),
    onClickUserAvatar(e: Event): void {
      if (store.getState().isLoggedIn) {
        return;
      }
      e.preventDefault();

      UserAuthTrackerFactory().onLoginModalOpen(StatsTealiumDataLayerEventReferenceEnum.HEADER);
      StatsGtmProviderService().send(userAvatarStatsGtmClickUserProfile(settingsGetPageType(configGetSettings())));
    },
    children: [],
  })
)(UserAvatarTemplate);
